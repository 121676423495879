/* global $ */
import uuid from 'uuid';
import deepEqual from 'deep-equal';

import { EMPTY_EDIT_DATA, pageSection, paths } from 'constants';
import { apiEndpoints } from 'apiService';
import {
    appendColumn,
    appendLayout,
    createColumn,
    createLayout
} from 'redux/reducers/section-utils';
import { isBentomaticSourcePage, isBlogRelated, isPopupPage, isSpecialtyPage } from 'page_utils';
import { default as BlogRiverMeta } from 'bento-components/blog-river/metadata';
import { default as BlogEntryMeta } from 'bento-components/blog-entry/metadata';
import moment from 'moment';

// TODO: do webpack magic to set this up.
// This comes from Django docs.
export function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

function sameOrigin(url) {
    // test that a given url is a same-origin URL
    // url could be relative or scheme relative or absolute
    var host = document.location.host; // host + port
    var protocol = document.location.protocol;
    var srOrigin = '//' + host;
    var origin = protocol + srOrigin;
    // Allow absolute or scheme relative URLs to same origin
    return (
        url === origin ||
        url.slice(0, origin.length + 1) === origin + '/' ||
        url === srOrigin ||
        url.slice(0, srOrigin.length + 1) === srOrigin + '/' ||
        // or any other URL that isn't scheme relative or absolute i.e relative.
        !/^(\/\/|http:|https:).*/.test(url)
    );
}

export function setupCSRFTocken() {
    $.ajaxSetup({
        beforeSend: function (xhr, settings) {
            if (!csrfSafeMethod(settings.type) && sameOrigin(settings.url)) {
                // Send the token to same-origin, relative URLs only.
                // Send the token only if the method warrants CSRF protection
                // Using the CSRFToken value acquired earlier
                var csrftoken = getCookie('csrftoken');
                xhr.setRequestHeader('X-CSRFToken', csrftoken);
            }
        }
    });
}

export function getCSRFCookie() {
    return getCookie('csrftoken');
}

export function getFirstElement(array, defaultValue = null) {
    if (array.length < 1) {
        return defaultValue;
    }
    return array[0];
}

export function getElementThatMatches(
    elements,
    matchValue,
    matchKey = 'id',
    defaultToFirst = true,
    fallbackValue = null
) {
    if (!matchValue && defaultToFirst) {
        return getFirstElement(elements, fallbackValue);
    }
    let candidates = elements.filter((element) => element[matchKey] === matchValue);
    return getFirstElement(candidates, fallbackValue);
}

// Updates any variables in URL params
// @param {string} path - route path
// @param {object} paramValues - any param values to replace
// @returns {string} - updated path
// @example
// returns '/console/sites/87/pages/1892'
// updateUrlParams('/console/sites/:siteId/pages/:pageId, {siteId: 87, pageId; 1892});
export const updateUrlParams = (path, paramValues) => {
    if (!path) {
        return '';
    }
    const params = path.split('/');

    // loop through all pieces of path
    const updatedParams = params.map((param) => {
        // if there is a variable, try to find it in route params
        if (param.indexOf(':') > -1) {
            const key = param.substring(1);
            const val = paramValues[key];

            return val;
        }

        return param;
    });

    return updatedParams.join('/');
};

export const handleReusableQueryParams = (locationSearch) => {
    const queryParams = new URLSearchParams(locationSearch);

    return {
        pageId: queryParams.get('redirectPageId'),
        reusableLayoutId: queryParams.get('redirectLayoutId'),
        redirectedReusableLayoutId: queryParams.get('redirectedReusableLayoutId'),
        treeRedirectLayoutId: queryParams.get('treeRedirectLayoutId')
    };
};

export const updateReusableQueryParams = (layoutId, pageId) => {
    return `?redirectLayoutId=${layoutId}&redirectPageId=${pageId}`;
};

export const makeComponentSettingsURL = (siteId, pageId, componentId) => {
    const path = paths.COMPONENT_SETTINGS;
    return updateUrlParams(path, { siteId, pageId, componentId });
};

export const makeReusableComponentSettingsURL = (siteId, reusableUUId, componentId) => {
    const path = paths.REUSABLE_COMPONENT_SETTINGS;
    return updateUrlParams(path, { siteId, reusableUUId, componentId });
};

export const makeReusableLayoutSettingsURL = (siteId, reusableUUId, layoutId) => {
    const path = paths.REUSABLE_LAYOUT_SETTINGS;
    return updateUrlParams(path, { siteId, reusableUUId, layoutId });
};
export const makePageReusableLayoutSettingsURL = (siteId, pageId, layoutId) => {
    const path = paths.PAGE_REUSABLE_SETTINGS;
    return updateUrlParams(path, { siteId, pageId, layoutId });
};

export const makeLayoutSettingsURL = (siteId, pageId, layoutId) => {
    const path = paths.LAYOUT_SETTINGS;
    return updateUrlParams(path, { siteId, pageId, layoutId });
};

export const makeComponentLayoutSettingsURL = (siteId, pageId, componentId, layoutId) => {
    const path = paths.COMPONENT_LAYOUT_SETTINGS;
    return updateUrlParams(path, { siteId, pageId, componentId, layoutId });
};

export const makeEditReusableURL = (siteId, reusableUUId, pageId, layoutId) => {
    const path = paths.REUSABLE_LAYOUT_SETTINGS;
    return updateUrlParams(path, { siteId, reusableUUId, layoutId });
};

/**
 * Checks wether a  component is allowed based on its restriction
 *
 * @param {object} component - registry component
 * @param {string} section - any page section name
 * @param {number} column - a column size
 * @param {string} siteType - type of site
 * @param {object} containerComponent - the container component
 * @returns {bool}
 */
export function checkComponentRestriction(
    component,
    section,
    column,
    siteType,
    containerComponent
) {
    const {
        allowedSections,
        allowedColumns,
        allowedSiteTypes,
        allowedComponents,
        ignoreAllowedColumnsFor
    } = component.meta.restriction;

    if (containerComponent) {
        return (
            allowedComponents &&
            allowedComponents.has(containerComponent.name) &&
            allowedColumns.has(column) &&
            allowedSiteTypes.has(siteType)
        );
    }
    return (
        (allowedColumns.has(column) ||
            (ignoreAllowedColumnsFor && ignoreAllowedColumnsFor.has(section))) &&
        allowedSiteTypes.has(siteType) &&
        allowedSections.has(section)
    );
}

/**
 * Checks whether blog components (BlogRiver, BlogEntry) are allowed on page
 * based on `blogRelated` flag
 * @param  {BentoComponent} component :component to be checked
 * @param  {Boolean} blogRelated :flag set to true if page is blog related. See page_utils.js
 * @return {Boolean} true if the component is allowed, false otherwise
 */
export function checkBlogComponents(component, blogRelated) {
    return (
        !blogRelated ||
        (component.meta.name !== BlogRiverMeta.name && component.meta.name !== BlogEntryMeta.name)
    );
}

/**
 * Checks whether a bentomatic consumer is allowed on page
 * based on the type of page
 * @param  {BentoComponent} component - component to be checked whether it's a bentomatic consumer
 * @param  {Boolean} pageData - object that contains data about the page being edited
 * @return {Boolean} true if the component is allowed, false otherwise
 */
export function checkBentomaticConsumer(component, pageData) {
    return (
        component.meta.name !== 'bentomatic' ||
        !(
            isSpecialtyPage(pageData) ||
            isBlogRelated(pageData) ||
            isBentomaticSourcePage(pageData) ||
            isPopupPage(pageData)
        )
    );
}

/**
 * Checks whether a component is allowed to be used
 * based on feature toggles
 * @param  {BentoComponent} component - component to be checked whether it's subject to feature toggles
 * @param  {Boolean} featureToggles - object that contains the name and active state of existing toggles
 * @return {Boolean} true if the component is allowed, false otherwise
 */
export function checkFeatureToggles(component, featureToggles) {
    if (featureToggles[component.meta.name]) {
        return featureToggles[component.meta.name];
    }
    return true;
}

export function setDiff(setA, setB) {
    return new Set([...setA].filter((x) => !setB.has(x)));
}

export function trimLeft(value, characters) {
    if (typeof value !== 'string' || typeof characters !== 'string') {
        return undefined;
    }
    while (value && characters && value.startsWith(characters)) {
        value = value.substr(characters.length);
    }
    return value;
}

export function trimRight(value, characters) {
    if (typeof value !== 'string' || typeof characters !== 'string') {
        return undefined;
    }
    while (value && characters && value.endsWith(characters)) {
        value = value.substr(0, value.length - characters.length);
    }
    return value;
}

export function trim(value, characters) {
    return trimRight(trimLeft(value, characters), characters);
}

export function trimWithEllipsis(text, threshold) {
    if (text.length <= threshold) {
        return text;
    }
    return text.substr(0, threshold).concat('...');
}

export function strSplice(value, start, deleteCount, insert) {
    if (typeof value !== 'string') {
        return;
    }

    let newValue = value.split('');
    newValue.splice(start, deleteCount, insert);

    return newValue.join('');
}

export function getTruncatedString(initialString, limit) {
    let result = initialString;
    if (initialString.length > limit) {
        let str = initialString.substring(0, limit);
        result = str.substring(0, str.lastIndexOf(' '));
    }
    return result;
}

export function withDefaultProps(cls, defaultProps = {}) {
    if (typeof cls !== 'function') {
        return cls;
    }

    cls.defaultProps = {
        ...cls.defaultProps,
        ...defaultProps
    };

    return cls;
}

export function filerFileURL(filerFile, download) {
    if (!filerFile) {
        return '';
    }
    if (!filerFile.id) {
        return filerFile.url || '';
    }
    if (download) {
        return apiEndpoints.FILER_FILE_LOCATION_DOWNLOAD.replace('{filerFileId}', filerFile.id);
    } else {
        return apiEndpoints.FILER_FILE_LOCATION.replace('{filerFileId}', filerFile.id);
    }
}

export const newEmptyContent = () => {
    const layoutId = uuid.v4();
    const columnId = uuid.v4();
    let state = { ...EMPTY_EDIT_DATA };

    state = createLayout(state, layoutId);
    state = appendLayout(state, layoutId);
    state = createColumn(state, columnId);
    state = appendColumn(state, layoutId, columnId);

    return state;
};

export const areVersionStructuresDifferent = (v1, v2) => {
    if (!v1 || !v2) {
        return false;
    }
    return (
        !deepEqual(v1.section, v2.section) ||
        !deepEqual(v1.page, v2.page) ||
        !deepEqual(v1.layouts, v2.layouts) ||
        !deepEqual(v1.columns, v2.columns) ||
        Object.keys(v1.components).length !== Object.keys(v2.components).length
    );
};

export const getDarkestThemeColor = (theme = {}) => {
    const { colorPalette } = theme;
    const blackHex = '#000';
    return (colorPalette && colorPalette.accent1) || blackHex;
};

export const getLightestThemeColor = (theme = {}) => {
    const { colorPalette } = theme;
    const whiteHex = '#fff';
    return (colorPalette && colorPalette.accent4) || whiteHex;
};

export const getMainThemeColor = (theme = {}) => {
    const { colorPalette } = theme;
    const blackHex = '#000';
    return (colorPalette && colorPalette.main) || blackHex;
};

export const findLayoutSection = (currentSiteState = {}, contentLayouts = {}, layoutId = '') => {
    const railSectionLayouts =
        (currentSiteState &&
            currentSiteState.rail &&
            currentSiteState.rail.data &&
            currentSiteState.rail.data.content &&
            currentSiteState.rail.data.content.layouts) ||
        {};
    const headerSectionLayouts =
        (currentSiteState &&
            currentSiteState.header &&
            currentSiteState.header.data &&
            currentSiteState.header.data.content &&
            currentSiteState.header.data.content.layouts) ||
        {};
    const footerSectionLayouts =
        (currentSiteState &&
            currentSiteState.footer &&
            currentSiteState.footer.data &&
            currentSiteState.footer.data.content &&
            currentSiteState.footer.data.content.layouts) ||
        {};
    if (Object.keys(railSectionLayouts).includes(layoutId)) {
        return pageSection.RAIL;
    }
    if (Object.keys(headerSectionLayouts).includes(layoutId)) {
        return pageSection.HEADER;
    }
    if (Object.keys(footerSectionLayouts).includes(layoutId)) {
        return pageSection.FOOTER;
    }
    if (Object.keys(contentLayouts).includes(layoutId)) {
        return pageSection.CONTENT;
    }
    return null;
};

export const findComponentSection = (
    currentSiteState = {},
    contentComponents = {},
    componentId = ''
) => {
    const railSectionComponents =
        (currentSiteState &&
            currentSiteState.rail &&
            currentSiteState.rail.data &&
            currentSiteState.rail.data.content &&
            currentSiteState.rail.data.content.components) ||
        {};
    const headerSectionComponents =
        (currentSiteState &&
            currentSiteState.header &&
            currentSiteState.header.data &&
            currentSiteState.header.data.content &&
            currentSiteState.header.data.content.components) ||
        {};
    const footerSectionComponents =
        (currentSiteState &&
            currentSiteState.footer &&
            currentSiteState.footer.data &&
            currentSiteState.footer.data.content &&
            currentSiteState.footer.data.content.components) ||
        {};
    if (Object.keys(railSectionComponents).includes(componentId)) {
        return pageSection.RAIL;
    }
    if (Object.keys(headerSectionComponents).includes(componentId)) {
        return pageSection.HEADER;
    }
    if (Object.keys(footerSectionComponents).includes(componentId)) {
        return pageSection.FOOTER;
    }
    if (Object.keys(contentComponents).includes(componentId)) {
        return pageSection.CONTENT;
    }
    return null;
};

export const arraysEqual = (arr1, arr2) => {
    if (arr1 === arr2) {
        return true;
    }
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; ++i) {
        if (!deepEqual(arr1[i], arr2[i])) {
            return false;
        }
    }
    return true;
};

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const colorLuminance = (hex, lum) => {
    // More info: https://www.sitepoint.com/javascript-generate-lighter-darker-color/
    // ColorLuminance("#69c", 0);      returns "#6699cc"
    // ColorLuminance("6699CC", 0.2);  "#7ab8f5" - 20% lighter
    // ColorLuminance("69C", -0.5);    "#334d66" - 50% darker
    // ColorLuminance("000", 1);       "#000000" - true black cannot be made lighter!

    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#',
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
};

/**
 * Method to build the ITS url for a filer file
 * @param {String} url - filer file url
 * @returns {String} the ITS url for that filer file
 */
export const getITSurl = (url) => {
    if (!url) {
        return;
    }
    let urlParts = url.split('/');
    let filerIdx = urlParts.indexOf('filer_public');
    if (filerIdx < 0) {
        return url;
    }
    const itsPrefix =
        (window.PBSBentoAdmin && window.PBSBentoAdmin.ITS_PREFIX) ||
        window.PBS.BentoConfig.ItsPrefix;
    let itsURL = itsPrefix + '/' + urlParts.slice(filerIdx + 1).join('/');
    return itsURL;
};

export const generateItsThumbnailUrl = (imgUrl, width = 200) => {
    if (
        typeof imgUrl === 'string' &&
        (imgUrl.toLowerCase().endsWith('.jpg') ||
            imgUrl.toLowerCase().endsWith('.jpeg') ||
            imgUrl.toLowerCase().endsWith('.png') ||
            imgUrl.toLowerCase().endsWith('.webp') ||
            imgUrl.toLowerCase().endsWith('.svg'))
    ) {
        return getITSurl(imgUrl + `?resize=${width}x`);
    }
    return null;
};

/**
 * returns true if the url points to a GIF image
 * @param {String} url
 */
export const isImgFormat = (url, extension = '.gif') => {
    if (!url) {
        return false;
    }
    return url.toLowerCase().endsWith(extension) || false;
};

export const isDefaultImage = (imageObject) => {
    if (!imageObject.url) {
        return false;
    }
    if (imageObject.isDefault) {
        return true;
    }
    return imageObject.url.toLowerCase().includes('default-image') || false;
};

export const transformUtcToLocal = (time) => {
    let utcTime = moment(time).utc();
    return utcTime.local().format('MM/DD/YYYY - h:mm A');
};

export const getVersion = () => {
    // We need to replace '+' with '.' for the dev and rc versions so that the version string matches with the one from Webpack build
    // If the strings don't match, when calling Sentry init from React, another release will be created in Sentry
    let version = window.PBSBentoAdmin?.versioning;
    if (version && version.indexOf('+') > -1) {
        return version.replace('+', '.');
    }
    return version;
};

export const getFullURL = (domain, overwriteUrl, path) => {
    let root = trimRight(domain, '/') + '/';
    if (overwriteUrl) {
        root = root + trim(overwriteUrl, '/') + '/';
    }

    return trim(root + trim(path, '/'), '/') + '/';
};

export const processProducerDomain = (domain, site, env) => {
    let processedDomain = domain;
    if (
        isSitePublished(site.publicationStart, site.publicationEnd) &&
        site.overwriteUrl &&
        ['producer', 'internal_pbs'].includes(site.siteType) &&
        env === 'production'
    ) {
        processedDomain = domain.replace(/^.+.pbs/, 'pbs');
    }
    return processedDomain;
};

export const filterUnavailableComponents = (c, siteType) => {
    const {
        meta: {
            restriction: { allowedSiteTypes }
        }
    } = c;
    return allowedSiteTypes.has(siteType);
};

export const componentPrettyName = (name) =>
    name
        .split('-')
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(' ');

/**
 *  Return the image URL after changed the domain name
 * @param fileUrl
 * @returns {*}
 */
export const getFileUrl = (fileUrl) => {
    const regex = /https?:\/\/[a-z0-9.]*\//i;
    return fileUrl.replace(regex, `${process.env.BENTO_CDN}`);
};

export function isSitePublished(publicationStart, publicationEnd) {
    const currentTime = Date.now();
    let isSitePublished = 0;

    let utcPublicationStart,
        utcPublicationEnd = 0;
    if (publicationStart !== undefined && publicationStart !== null) {
        utcPublicationStart = new Date(publicationStart).getTime();
    }
    if (publicationEnd !== undefined && publicationEnd !== null) {
        utcPublicationEnd = new Date(publicationEnd).getTime();
    }

    if (utcPublicationStart !== 0 && currentTime > utcPublicationStart) {
        if (utcPublicationEnd === 0 || currentTime < utcPublicationEnd) {
            isSitePublished = 1;
        }
    }
    return isSitePublished;
}

/**
 *  Deletes (un-defines) multiple properties for a given object
 *
 * @param obj from which the props should be removed
 * @param propsToBeDeleted array of obj properties to be removed,
 * can also be a partial object composed of properties to be removed
 * @returns {*} mutated obj
 */
export function unDefineProperties(obj, propsToBeDeleted) {
    if (typeof propsToBeDeleted === 'object' && propsToBeDeleted !== null) {
        if (Array.isArray(propsToBeDeleted)) {
            propsToBeDeleted.forEach((propName) => delete obj[propName]);
        } else {
            Object.entries(propsToBeDeleted).forEach(([propName]) => delete obj[propName]);
        }
    }
    return obj;
}
