import update from 'immutability-helper';

import { getPageSection } from 'redux/selectors/section';
import { COLUMN_TYPES } from 'constants';
import {
    regenerateComponentIds,
    regenerateLayoutIds,
    shouldIgnoreDataChange,
    shouldIgnoreSettingsChange
} from './utils';
import uuid from 'uuid';
import { unDefineProperties } from '../../utils';

const MAX_COLUMNS = 12;

export function createComponent(state, component) {
    let nextComponents = { ...state.components };
    nextComponents[component.id] = component;

    let nextState = {
        ...state,
        components: {
            ...nextComponents
        }
    };

    return nextState;
}

export function appendComponent(state, columnId, componentId, appendAfterId) {
    let nextColumns = { ...state.columns };
    if (!appendAfterId) {
        nextColumns[columnId] = {
            ...nextColumns[columnId],
            components: [componentId, ...nextColumns[columnId].components]
        };
    } else {
        const position = nextColumns[columnId].components.indexOf(appendAfterId) + 1;
        nextColumns[columnId] = {
            ...nextColumns[columnId],
            components: [
                ...nextColumns[columnId].components.slice(0, position),
                componentId,
                ...nextColumns[columnId].components.slice(position)
            ]
        };
    }
    let nextState = {
        ...state,
        columns: {
            ...nextColumns
        }
    };

    return nextState;
}

export function appendComponentContent(state, content, newComponentId, appendAfterId, columnId) {
    let newColumns = { ...state.columns };

    let newContent = JSON.parse(JSON.stringify(content));
    newContent = regenerateComponentIds(newContent, newComponentId);

    if (!appendAfterId) {
        newColumns[columnId] = {
            ...newColumns[columnId],
            components: [newContent.component.id, ...newColumns[columnId].components]
        };
    } else {
        const position = newColumns[columnId].components.indexOf(appendAfterId) + 1;
        newColumns[columnId] = {
            ...newColumns[columnId],
            components: [
                ...newColumns[columnId].components.slice(0, position),
                newContent.component.id,
                ...newColumns[columnId].components.slice(position)
            ]
        };
    }
    let newState = {
        ...state,
        columns: {
            ...newColumns
        },
        components: {
            ...state.components,
            [newContent.component.id]: newContent.component
        }
    };

    return newState;
}

export function deleteComponent(state, componentId) {
    let nextComponents = { ...state.components };
    delete nextComponents[componentId];

    return {
        ...state,
        components: {
            ...nextComponents
        }
    };
}

export function removeComponentErrorBoundary(state, componentId) {
    let nextState = { ...state };
    let nextColumns = nextState.columns;
    //in case of duplicate component IDs this search will go through all columns
    //and look for the problematic ID, then remove it
    Object.keys(nextColumns).forEach((key) => {
        let columnComponents = nextColumns[key].components;
        let index = columnComponents.indexOf(componentId);
        if (index >= 0) {
            columnComponents.splice(index, 1);
        }
    });

    nextState = {
        ...nextState,
        columns: {
            ...nextColumns
        }
    };

    return nextState;
}

export function removeComponent(state, columnId, componentId) {
    const column = state.columns && state.columns[columnId] ? { ...state.columns[columnId] } : {};
    if (!column || !column.components) {
        return state;
    }

    return {
        ...state,
        columns: {
            ...state.columns,
            [columnId]: {
                ...column,
                components: [...column.components.filter((id) => id !== componentId)]
            }
        }
    };
}

export function changeComponentSettings(state, componentId, componentSettings) {
    let component = state.components[componentId];
    const componentSettingsKeys = Object.keys(componentSettings);
    if (!component) {
        return state;
    }
    let nextComponent = {
        ...component,
        settings: {
            ...component.settings,
            ...componentSettings
        }
    };
    let nextComponents = {
        ...state.components,
        [componentId]: nextComponent
    };
    return {
        ...state,
        components: nextComponents,
        hasComponentSettingsChanges:
            state.hasComponentSettingsChanges || !shouldIgnoreSettingsChange(componentSettingsKeys)
    };
}

export function changeComponentData(state, componentId, componentData) {
    let component = state.components[componentId];
    const componentDataKeys = Object.keys(componentData);
    if (!component) {
        return state;
    }
    let nextComponent = {
        ...component,
        data: {
            ...component.data,
            ...componentData
        }
    };
    let nextComponents = {
        ...state.components,
        [componentId]: nextComponent
    };
    return {
        ...state,
        components: nextComponents,
        hasComponentDataChanges:
            state.hasComponentDataChanges || !shouldIgnoreDataChange(componentDataKeys)
    };
}

export function changeComponentPosition(state, columnId, componentId, index) {
    let nextState = { ...state };
    let nextColumns = { ...nextState.columns };
    let nextColumn = { ...nextColumns[columnId] };
    let nextComponents = [...nextColumn.components];
    let currentComponentIndex = nextComponents.indexOf(componentId);

    nextComponents.splice(currentComponentIndex, 1);
    nextComponents.splice(index, 0, componentId);

    nextState = {
        ...state,
        columns: {
            ...state.columns,
            [columnId]: {
                ...state.columns[columnId],
                components: nextComponents
            }
        }
    };
    return nextState;
}

export function createColumn(state, columnId, columnSize = MAX_COLUMNS) {
    let nextColumns = { ...state.columns };
    nextColumns[columnId] = {
        id: columnId,
        size: columnSize,
        components: []
    };

    let nextState = {
        ...state,
        columns: {
            ...nextColumns
        }
    };

    return nextState;
}

export function createReusableColumn(state, columnId, columnSize = MAX_COLUMNS) {
    const tempColumnId = uuid();

    let defaultReusableComponent = {
        id: tempColumnId,
        name: 'reusable-block',
        isReusableDefaultComponent: true,
        restriction: {
            allowedSections: ['header', 'content', 'footer', 'component', 'rail'],
            allowedSiteTypes: ['station', 'producer', 'internal_pbs'],
            allowedColumns: [12, 6, 8, 4, 3, 100]
        }
    };

    let nextComponent = {
        ...state.components
    };
    nextComponent[tempColumnId] = defaultReusableComponent;

    let componentList = [];
    componentList.push(tempColumnId);

    let nextColumns = { ...state.columns };
    nextColumns[columnId] = {
        id: columnId,
        size: columnSize,
        isReusableDefaultColumn: true,
        components: componentList
    };

    let nextState = {
        ...state,
        columns: {
            ...nextColumns
        },
        components: {
            ...nextComponent
        }
    };

    return nextState;
}
export function appendColumn(state, layoutId, columnId) {
    let nextLayouts = { ...state.layouts };
    nextLayouts[layoutId] = {
        ...nextLayouts[layoutId],
        columns: [...nextLayouts[layoutId].columns, columnId]
    };
    let nextState = {
        ...state,
        layouts: {
            ...nextLayouts
        }
    };

    return nextState;
}

export function deleteColumn(state, columnId) {
    let nextColumns = { ...state.columns };
    delete nextColumns[columnId];

    return {
        ...state,
        columns: {
            ...nextColumns
        }
    };
}

export function removeColumn(state, layoutId, columnId) {
    let nextState = { ...state };
    let nextLayouts = nextState.layouts;
    let index = nextLayouts[layoutId].columns.indexOf(columnId);
    if (index >= 0) {
        nextLayouts[layoutId].columns.splice(index, 1);
    }
    nextState = {
        ...nextState,
        layouts: {
            ...nextLayouts
        }
    };

    return nextState;
}

export function createLayout(state, layoutId) {
    let nextLayouts = { ...state.layouts };

    return {
        ...state,
        layouts: {
            ...nextLayouts,
            [layoutId]: {
                id: layoutId,
                name: '',
                type: COLUMN_TYPES[0].colTitle,
                columns: [],
                isFullWidth: false,
                isFullHeight: false,
                showBackgroundColor: false,
                showBackgroundImage: false,
                isContentVerticallyCentered: false,
                padding: [
                    {
                        key: 'paddingTop',
                        label: 'Top',
                        value: 0
                    },
                    {
                        key: 'paddingRight',
                        label: 'Right',
                        value: 0
                    },
                    {
                        key: 'paddingBottom',
                        label: 'Bottom',
                        value: 0
                    },
                    {
                        key: 'paddingLeft',
                        label: 'Left',
                        value: 0
                    }
                ]
            }
        }
    };
}

export function createReusableLayout(state, layoutId) {
    let nextLayouts = { ...state.layouts };

    return {
        ...state,
        layouts: {
            ...nextLayouts,
            [layoutId]: {
                id: layoutId,
                name: '',
                type: COLUMN_TYPES[0].colTitle,
                columns: [],
                isFullWidth: false,
                isFullHeight: false,
                showBackgroundColor: false,
                showBackgroundImage: false,
                isContentVerticallyCentered: false,
                isReusable: true,
                padding: [
                    {
                        key: 'paddingTop',
                        label: 'Top',
                        value: 0
                    },
                    {
                        key: 'paddingRight',
                        label: 'Right',
                        value: 0
                    },
                    {
                        key: 'paddingBottom',
                        label: 'Bottom',
                        value: 0
                    },
                    {
                        key: 'paddingLeft',
                        label: 'Left',
                        value: 0
                    }
                ],
                openLayoutSettings: true,
                reusableUUId: 'default',
                reusableId: 0
            }
        }
    };
}

export function appendLayout(state, layoutId, appendAfter) {
    let sectionKey = state.section ? 'section' : 'page';
    let newLayouts = [...getPageSection(state).layouts];
    if (!appendAfter) {
        //insert before the first layout
        newLayouts.splice(0, 0, layoutId);
    } else {
        //insert after layout with ID appendAfter
        newLayouts.splice(newLayouts.indexOf(appendAfter) + 1, 0, layoutId);
    }
    return {
        ...state,
        [sectionKey]: {
            layouts: newLayouts
        }
    };
}

export function appendLayoutContent(state, content, newLayoutId, appendAfter) {
    const sectionKey = state.section ? 'section' : 'page';
    let newLayouts = (state.page && state.page.layouts) || [];

    let newContent = JSON.parse(JSON.stringify(content));
    newContent = regenerateLayoutIds(newContent, newLayoutId);

    if (!appendAfter) {
        //insert before the first layout
        newLayouts.splice(0, 0, newLayoutId);
    } else {
        //insert after layout with ID appendAfter
        newLayouts.splice(newLayouts.indexOf(appendAfter) + 1, 0, newLayoutId);
    }
    const newState = {
        ...state,
        layouts: { ...state.layouts, [newLayoutId]: newContent.layout },
        components: { ...state.components, ...newContent.components },
        columns: { ...state.columns, ...newContent.columns },
        [sectionKey]: {
            layouts: newLayouts
        }
    };
    return newState;
}

export function deleteLayout(state, layoutId) {
    let nextLayouts = { ...state.layouts };
    delete nextLayouts[layoutId];

    return {
        ...state,
        layouts: {
            ...nextLayouts
        }
    };
}

export function removeLayout(state, layoutId) {
    let layouts = getPageSection(state).layouts;
    let layoutIndex = layouts.indexOf(layoutId);
    let sectionKey = state.section ? 'section' : 'page';
    let nextPageLayouts = [
        ...getPageSection(state).layouts.slice(0, layoutIndex),
        ...getPageSection(state).layouts.slice(layoutIndex + 1, layouts.length)
    ];

    return {
        ...state,
        [sectionKey]: {
            layouts: nextPageLayouts
        }
    };
}

export function moveLayout(state, layoutId, index) {
    let currentLayoutIndex = getPageSection(state).layouts.indexOf(layoutId);
    let sectionKey = state.section ? 'section' : 'page';
    let nextState = update(state, {
        [sectionKey]: {
            layouts: {
                $splice: [
                    [currentLayoutIndex, 1],
                    [index, 0, layoutId]
                ]
            }
        }
    });

    return nextState;
}

export function changeLayoutType(state, layoutId, layoutType) {
    let nextLayouts = { ...state.layouts };

    return {
        ...state,
        layouts: {
            ...nextLayouts,
            [layoutId]: {
                ...nextLayouts[layoutId],
                type: layoutType
            }
        }
    };
}

export function changeLayoutSettings(state, layoutId, layoutSettings) {
    let nextLayouts = { ...state.layouts };
    return {
        ...state,
        layouts: {
            ...nextLayouts,
            [layoutId]: {
                ...nextLayouts[layoutId],
                ...layoutSettings
            }
        }
    };
}
export function deleteLayoutSettings(state, layoutId, settingsToBeDeleted) {
    let nextLayouts = { ...state.layouts };
    let nextLayout = { ...nextLayouts[layoutId] };
    unDefineProperties(nextLayout, settingsToBeDeleted);
    return {
        ...state,
        layouts: {
            ...nextLayouts,
            [layoutId]: nextLayout
        }
    };
}

export function toggleLayoutDelete(state, layoutId, hidden) {
    let nextLayouts = { ...state.layouts };

    return {
        ...state,
        layouts: {
            ...nextLayouts,
            [layoutId]: {
                ...nextLayouts[layoutId],
                hideDelete: hidden
            }
        }
    };
}
